
.section_head{
    font-size: 18px;
    font-weight: bold;
    color: #474747;
}

.overall_guest_satisfaction_cont{
    background-color: #fff;
    margin-top: 15px;
    font-family: 'Lato';
    padding: 20px;
}

.overall_guest_satisfaction_cont > h4{
    margin-bottom: 25px;
    
}

.overall_guest_satisfaction_right{
    background-color: #fff;
    padding: 20px;
}

.overall_guest_satisfaction{
    display: grid;
    grid-template-columns: 1fr 2px 1fr 2px 1fr;
    grid-column-gap: 10px;
}

.vertical_line{
    width: 1px;
    background-color: #ccc;
    height: 80%;
    align-self: center;
}

.recommend_scale_cont{
    display: grid;
}

.recommend_scale_cont > p{
    text-align: center;
    width: 70%;
    margin: 0 auto;
    /* margin-bottom: 25px; */
    align-self: flex-start;
}

.scale_btn_cont{
    display: flex;
    align-self: flex-start;
    margin: 0 auto;
}

.scale_btn_cont > button{
    border: 1px solid #707070;
    background: none;
}

.scale_btn_cont > button:focus{
    background:#68BE68;
    color: #fff;
}

.how_guests_felt_chart_cont{
    width: 100px;
    height: 300px;

}

.carousel_chart_container{
    width: 250px;
}

.guest_stay_cont{
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-top: 15px;
    grid-column-gap: 15px;
    
}

.guest_stay_left{
    background-color: #fff;
    padding: 20px;
}

.guest_stay_right{
    background-color: #fff;
    padding: 20px;
}

.guest_stay_right > h4{
    font-size: 18px;
}

.guest_stay_graphs_cont{
    display: flex;
    overflow-x: scroll;
    width: 680px;
}

.guest_stay_graphs_cont > div{
    flex: 0 0 auto;
    margin: 0 10px;
}

.guest_stay_chart_link{
    color: #92B860;
    text-decoration: underline;
    font-size: 15px;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
    font-family: 'Nunito Sans';
}

/** HOTEL PERFORMANCE BY SURVEY */

.hotel_perf_by_survey{
    margin-top: 10px;
}

.hotel_perf_by_survey > p{
    margin: 0;
}

.hotel_perf_by_survey > div{
    display: flex;
    align-items: center;
}

.hotel_perf{
    margin: 0;
    margin-left: auto;
}

.hotel_perf_cont{
    height: 250px;
    overflow-y: auto;
}

/*------------------------------------------**/

/** HOTEL PERFORMANCE BY SURVEY */

.most_used_service_by_guest_cont{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 15px;
    margin-top: 15px;
}

.most_used_service_by_guest_left{
    background-color: #fff;
    padding: 20px;
}

.most_used_service_by_guest_right{
    background-color: #fff;
    padding: 20px;
}

.most_used_service_by_guest_left > h4{
    margin-bottom: 25px;
}

/*-----------------------------*/


/**SURVEY STATS*/

.most_used_service_by_guest_right{
    width: 250px;
}

.survey_stats_cont{
    margin-top: 25px;
}

.survey_stats_head{
    color: #474747;
    font-size: 14px;
    text-align: center;
}

.survey_stats_perc{
    color: #232425;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.survey_stats_legend_cont{
    display: flex;
    margin-top: 30px;
}

.survey_stats_legend_cont > p{
    font-size: 12px;
    color: #232425;
}

.survey_stats_legend_cont > p::before{
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 5px;
}

.survey_stats_legend_cont > p:first-child::before{
    background-color: #C04DD8;
}

.survey_stats_legend_cont > p:last-child::before{
    background-color: grey;
}

.survey_stats_legend_cont > p:last-child{
    margin-left: auto;
}


/**---------------*/

.heading{
    font-size: 28px;
    font-family: 'Lato';
    font-weight: bold;
    color: #747474;
    margin: 30px 0;
}


/*  PROBLEMS AND SUGGESTIONS  */

.prob_occ_cont > p{
    text-align: center;
}

.prob_occ_point{
    font-size: 60px;
    text-align: center;
    color: #FF6161B8;
}

.prob_by_dept_cont > p{
    text-align: center;
}

.department_cont > p{
    margin: 0;
    font-size: 14px !important;
}

.department_cont > p span{
    color: salmon;
    font-size: 15px !important;
    margin-left: 10px;
}

.prob_dept_cont{
    height: 150px;
    overflow-y: auto;
}

.problem_and_sugg_left{
    
}

.problem_and_sugg_right{
    background-color: #fff;
    padding: 20px;
}

.problem_and_sugg_left .overall_guest_satisfaction_cont{
    
    margin: 0;
}

/**----------------------------------*/


/* SUGGESTIONS  */

.suggestions_cont{
    display: grid;
    grid-template-areas: 
    'area area pool pool'
    'coffee room service play'
    'coffee food service spa'
    'bath bath service counter'
    'reception reception service counter'  ;
    font-size: 15px !important;
}

.suggestions_cont > p{
    margin: 0;
    cursor: pointer;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
}

.suggestions_cont > p:hover{
   transform: scale(1.08);
   -webkit-transform: scale(1.08);
   -moz-transform: scale(1.08);
   -ms-transform: scale(1.08);
   -o-transform: scale(1.08);
}


.area{ 
    grid-area: area;
    color: #B88F60;
    justify-self: center;
}
.pool{ 
    grid-area: pool;
    color: #8960B8;
}
.coffee{
    grid-area: coffee;
    color: #92B860;
}
.room{
    grid-area: room;
    color: #92B860;
}
.food{
    grid-area: food;
    color: #609DB8;
    justify-self: flex-end;
    font-weight: bold;
    font-size: 17px;
}
.service{
    grid-area: service;
    color: #B86063;
}
.play{
    grid-area: play;
    color: #92B860;
}
.spa{
    grid-area: spa;
    color: #B86060;
}
.counter{
    grid-area: counter;
    color: #92B860;
}
.bath{
    grid-area: bath;
    color: #609DB8;
    justify-self: center;
}
.reception{
    grid-area: reception;
    color: #B860B8;
}


.rotate_90{
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.suggestions_head{
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
}

.keywords_from_drilldown > h4{
    font-size: 20px;
    color: #8960B8;
    text-align: center;
    margin: 20px 0;
}

.drilldown_suggestion_cont{
    height: 150px;
    overflow-y: auto;
}

.drilldown_suggestion_cont > p{
    margin: 0;
    color: #828282;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}

.drilldown_suggestion_head_cont{
    margin-bottom: 20px;
    text-align: center;
}

.drilldown_suggestion_head_cont > p{
    margin: 0;
    color: #555555;
    font-size: 18px;
}

.suggestion_pool{
    background-color: #8960B8;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #fff;
    padding: 5px;
}

/*-------------------------------------*/

/* GUEST RESPONSE */

.problem_and_sugg_bottom{
    display: grid;
    margin-top: 15px;
    background-color: #FAFEF5;
    grid-template-columns: 1.5fr 2.5fr;
    grid-column-gap: 15px;
}

.guest_response_cont{
    background-color: #fff;
    padding: 20px;
}

.chart_label_cont{
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.chart_label_cont > li{
    font-size: 12px;
    list-style: none;
}

.dot{
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #000;
    margin-right: 5px;
}


/*--------------------------*/

/* ENVIROMENTAL QUESTIONS */

.env_question_cont > P{
    width: 70%;
    margin: 0 auto;
    color: #B88F60;
    text-align: left;
    margin-top: 20px;
}

.env_question_cont > div{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.env_question_yes_cont{
   width: 30%;
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.env_question_yes_cont > p{
    margin: 0;
    margin-bottom: 5px;
}

.env_question_yes_dot{
    content: "";
    width: 35px;
    
    background-color: #68BE68;
    border: 1px solid #2C7A2C;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.env_question_no_dot{
    background-color: #FF6161;
    border: 1px solid #B43E3E;
}

/*-------------------------------**/

/* MNAUALLY ENTERED QUESTIONS*/

.manually_entered_quest_cont{
    background-color: #fff;
    padding: 20px;
}

select.manually_entered_ques{
    border: 0.6px solid #707070;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: block;
    margin-left: auto;
}

.manually_entered_questions{
    display: flex;
    width: 60rem;
    overflow-x: auto;
}

.manually_entered_questions > div{
    flex: 0 0 auto;
    width: 40%;
}

/*-------------------------------------*/

@media only screen and (max-width:450px){

    .vertical_line{
        display: none;
    }

    .overall_guest_satisfaction_cont{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px ;
        -moz-border-radius: 10px ;
        -ms-border-radius: 10px ;
        -o-border-radius: 10px ;
    }

    .guest_response_cont{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
}

    .overall_guest_satisfaction{
        grid-template-columns: 1fr;
       
    }

    .overall_guest_satisfaction > div{
        display: none;
    }

    .overall_guest_satisfaction > div:first-child{
        display: block;
    }

    .guest_stay_cont{
        grid-template-columns: 1fr;
        margin-top: 15px;
        grid-row-gap: 15px;
        /* box-shadow: 0px 0px 6px #00000029; */
        /* background: #fff; */
    }

    .guest_stay_graphs_cont{
        width: 300px;
    }

    .guest_stay_left{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .guest_stay_right{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .most_used_service_by_guest_cont{
       
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
        margin-top: 15px;
        /* box-shadow: 0px 0px 6px #00000029; */
    }

    .problem_and_sugg_bottom{
       
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
    }

    .problem_and_sugg_right{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
}

    .manually_entered_questions{
        display: flex;
        width: 28rem;
        overflow-x: auto;
        
}

    .manually_entered_questions > div{
        width: 50%;
    }
    
    .manually_entered_quest_cont{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .section_head{
        font-size: 15px !important;
      
    }

    .most_used_service_by_guest_left{
        width: 100%;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .most_used_service_by_guest_right{
        width: 100%;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .heading{
        font-size: 20px !important;
        
    }
    
    .prob_occ_point{
        font-size: 50px !important;
        text-align: center;
        color: #FF6161B8;
    }
    
    .drilldown_suggestion_cont{
        text-align: center;
    }


}