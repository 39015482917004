.MainContainer{
    font-family: 'Lato', sans-serif;
    padding: 7%;
    /* background-color: #e0e8ff; */
    /* background: linear-gradient(to right, #f9f9f9, #e3eefa); */
    background-color: #F9FAFA

}


.HeaderContainer{
    display: flex;
    height: 250px;
    background: linear-gradient(rgba(0, 0, 0, 0.253),rgba(0, 0, 0, 0.267));
    background-position: 0% 100%;
    background-size: cover;
    border: 1px solid #707070;
    color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
}

.HotelLogo{
    position: absolute;
    top: 25px;
    left: 25px;
    text-align: justify;
}

.HotelLogo h4{
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 900;
    margin: 0;
    padding: 0;
    line-height: 20px;
}

.HotelLogo span{
    font-size: 10px;
    margin: 0;
}

.HeaderContainer h1{
    margin-bottom: 30px;
}

.HeaderContainer p{
    font-size: 22px;
    letter-spacing: -0.46px;
    color: #FFFBFB;
    width: 60%;
    margin: 0 auto;
}

.HeaderContainer select{
    margin-top: 20px;
    padding: 5px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #808080 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.subHeading{
    text-align: center;
    color: #474747;
    font-size: 22px;
    margin-bottom: 20px;
}

.SurveyQuestionsContainer{
    /* width: 50%; */
    /* margin: 0 auto; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* grid-template-columns: 1fr 4fr; */
   
}

.SurveyQuestionsContainer > div{
    /* padding: 10px; */
   
}

.Question{
    margin-top: 20px;
    font-size: 18px;
}

.SliderContainer{
    height: 200px;
}

.ProgressSlider{
    position: relative;
}


.SliderMarks span{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 15px;
    width: 20px;
    text-align: center;
    z-index: 2;
    color: #fff;
    background-color: #7EA63F;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.SliderMarks span:first-child{
    top: 0;
    left: 8px;
}

.SliderMarks span:nth-child(2){
    top: 50px;
    left: 8px;
}

.SliderMarks span:nth-child(3){
    top: 100px;
    left: 8px;
}

.SliderMarks span:nth-child(4){
    top: 150px;
    left: 8px;
}

.SliderMarks span:nth-child(5){
    top: 200px;
    left: 8px;
    background-color: transparent;
    color: #7EA63F;
    font-size: 20px;
}

.QuestionsContainer h4{
    color: #7EA63F;
}

.Required{
   position: relative;
   top: 25px;
   color: red;
}

.BackNextContainer{
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
}

.BackNextContainer p{
    font-size: 18px;
    color: #C2C2C2;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
}

.BackNextContainer p:hover{
    color: #000;
}

.BackNextContainer >p:first-child{
    float: left;
}

.BackNextContainer >p:last-child{
    float: right;
}

.EmptySurveyContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #4472C4;
    height: 90vh;
    width: 100vw;
    overflow: hidden;
}

.EmptySurveyContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    font: bold 8em 'nunito';
    width: 35vw;
}

.EmptySurveySubContent{
    display: flex;
    font: 300 20px 'nunito';
}

.EmptySurveySubContent > div:not(:nth-child(2n)){
    padding: 15px;
}

.EmptySurveySubContent > div{
    width: max-content;
}

.EmptySurveyButton > button{
    padding: 12px;
    border: none;
    border-radius: 12px;
    color: #4472C4;
}

.EmptySurveyButton > button:focus{
    outline: none;
}

.VerticalLine{
    border: 1px solid white;
}

.EmptySurveyImg{
    margin-top: 20px;
    width: 55vw;
}

.EmptySurveyImg > img{
    width: 100%;
}

.EmptySurveyFooter{
    background-color: #4472C4;
    height: 10vh;
    color: white;
    text-align: center;
    font: 300 18px 'nunito';
}

@media only screen and (max-width: 1200px){
    .EmptySurveyContent{
        font-size: 5em;
    }
    .EmptySurveyButton > button{
        padding: 8px;
    }
    .EmptySurveySubContent{
        font-size: 16px;
    }
}

@media only screen and (max-width: 900px){
    .EmptySurveyContent{
        font-size: 4em;
    }
    .EmptySurveyButton > button{
        padding: 6px;
    }
    .EmptySurveySubContent{
        font-size: 13px;
    }
}

@media only screen and (max-width: 650px){
    .EmptySurveyContent{
        font-size: 2em;
    }
    .EmptySurveyButton > button{
        padding: 2px;
    }
    .EmptySurveySubContent{
        font-size: 10px;
    }
}

@media only screen and (max-width:450px){

    .SliderContainer{
        height: 30px;
    }

    .SurveyQuestionsContainer{
        width: 100%;
        /* grid-template-columns: 1fr; */
    }

    .SliderMarks span:first-child{
        top: 8px;
        left: 0;
    }
    
    .SliderMarks span:nth-child(2){
        top: 8px;
        left: 25%;
    }
    
    .SliderMarks span:nth-child(3){
        top: 8px;
        left: 50%;
    }
    
    .SliderMarks span:nth-child(4){
        top: 8px;
        left: 75%;
    }
    
    .SliderMarks span:nth-child(5){
        top: 8px;
        left: 95%;
        background-color: transparent;
        color: #7EA63F;
        font-size: 20px;
    }

    .slider_check_icon{
        font-size: 20px !important;
    }

    .section_head, .section_head > span{
        font-size: 18px !important;
        text-align: center;
    }

    .Question{
        text-align: center;
        font-size: 15px !important;
    }

    .BackNextContainer p{
        font-size: 15px !important;
    }

    .EmptySurveyContent{
        font-size: 1.5em;
    }
    .EmptySurveyButton > button{
        padding: 1px;
    }
    .EmptySurveySubContent{
        font-size: 7px;
    }

}