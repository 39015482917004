.header_title {
text-align: left;
font: Condensed Bold 28px 'Nunito';
letter-spacing: 0px;
color: #747474;
opacity: 1;
text-transform: uppercase;
margin-left: 20px;
}

.hotel_list{
    display: flex;
    position: relative;
    justify-content: flex-start;
}