.TabContainer{
    display: flex;
    height: 50px;
}

.TabContainer > p{
    margin: 0;
    /* background: #fff; */
    margin: 5px 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 25px;
    color: #fff;
    background: rgba(107, 129, 190, 0.2);
    border-radius: 4px;
    /* border-bottom: 3px solid #fff; */
    width: max-content;
    height: 44px;
}

.selectedTab{
  background: #283953 !important;
}


  @media (min-width: 768px) and (max-width: 1024px){

    .TabContainer{
      background: #fff;
      box-shadow: 1px 2px 10px #00000029;
    }

    .TabContainer > p{
      padding: 10px 15px;
    }

  }

  @media only screen and (max-width:480px){


    .TabContainer {
      grid-column: 1/-1;
      flex-wrap: wrap;
      margin-top: 15px;
      width: 100%; /* Set the width to 100% */
      box-sizing: border-box; /* Include padding and border in the total width */
    }
    
    .TabContainer > p {
      font-size: 14px !important;
    }
    
    .TabContainer > p:hover {
      border-bottom: none;
    }
    
      
  }

  