.assigned_delete_popup{
    position: fixed;
    background-color: #fff;
    top: 30%;
    left: 35%;
    z-index: 10;
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.assigned_delete_popup_fixed{
    position: fixed;
    background-color: #fff;
    height: 200px;
    width: 320px;
    top: 40%;
    left: 45%;
    z-index: 10;
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.assigned_delete_popup button,
.assigned_delete_popup_fixed button{
    background: none;
    border: 1px solid #7ea63f;
    color: #7ea63f;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.assigned_delete_popup_fixed p{
    margin-bottom: 2.5rem;
}

.assigned_delete_popup_fixed button {
    width: 80px;
    padding: 5px;
}

.assigned_delete_popup button:nth-child(2),
.assigned_delete_popup_fixed button:nth-child(2){
    float: left;
}

.assigned_delete_popup button:nth-child(3),
.assigned_delete_popup_fixed button:nth-child(3){
    float: right;
    color: salmon;
    border: 1px solid salmon;    
}

.confirm_msg {
    position: relative;
    padding: 20px;
    text-align: center;
    top: 5%;

}

.flex_div {
    display: flex;
    align-items: stretch;
    margin-top: 3em;
    margin-left: 1em;
    padding: 0rem 1rem;
}

.circle{
    font-size: 26px;
    height: 50px;
    width: 50px !important ;
    border-radius: 50%;
    background-color: white;
    margin-right: 10px;
    transform: translateY(-10px); 
    cursor: pointer;
}

.chartArrow{
    cursor: pointer;
}

.chartArrow:focus{
    outline: none;
}

.chartArrow:active{
    outline: none;
}

.extraHeaderContents{
    margin-left: auto;
    display: flex;
    width: max-content;
}

.BackBtn{
    margin-left: 15px;
    transform: translateY(5px);
}

.container{
    background: #fff;
    position: relative;
    animation-duration: 0.8s;
    animation-name: fadeIn;
    width: 97%;
    padding: 20px;
    /* font-family: 'Lato'; */
    margin-bottom: 3em;
    margin-left: 1em;
    border-radius: 12px;
    margin-top: 5px;
}

.lineChart{
    margin-bottom: 45px;
}

.ScaleButton{
    margin-left: auto;
    position: absolute;
    right: 0;
    transform: translate3D(-10px,-30px,0);
    cursor: pointer;
    z-index: 999;
}

.xAxisTitle{
    margin-left:45%;
    margin-bottom: 20px;
    margin-top: 5px;
    /* font-family: 'nunito'; */
    font-size: 1em;
    font-weight: bold;
    position: absolute;
}

.xAxisSlider{
    margin-top: 5%;
    margin-left: 30%;
    transform: translateY(-25px);
    position: absolute;
}

.yAxisTitleContainer{
    background-color: white;
    display: flex;
    left:20px;
    position: absolute;
    height: 400px;
    width:30px;
    /* z-index: 1000; */
    transform: translateY(10px);
}

.yAxisTitle{
    /* font-family: 'nunito';   */
    font-size: 1em;
    font-weight: bold;
    writing-mode:vertical-rl;
    transform: rotate(180deg);
    height: max-content;
    background-color: white;
    margin-top: 150px;
}

.yAxisSliderContainer{
    margin-top: 8%;
    cursor: pointer;
    z-index: 1000;
    background-color: white;
    position: absolute;
    height:400px;
    width:20px;
    transform: translate3D(20px,-40px,0);
}

.yAxisSlider{
    margin-top: 25px;
}

.EditableFieldInput{
    border:none;
    border-bottom: 1px solid gray;
    border-radius: 0;
    box-shadow: none;
}

.MoreItems{
    cursor: pointer;
    transform: translateZ(5px);
}

.MoreItems:hover{
    font-size: 1.3em;
}

.MoreItems:hover .ListDropdown{
    display: block;
    padding: 1em;
    box-shadow: 3px 3px 3px rgb(0 0 0/ 0.3);
    margin-bottom: 2px;
}
.ListDropdown{
    background-color: white;
    display: none;
    font-size: 0.8em;
    position: absolute;
    z-index: 1000;
    border-radius: 6px;
    list-style: none;
}

.toolTip{
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(8, 0, 54);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    margin: 5px 0px;
}

.toolTip p{
    margin: 0px
}

#bulletChart {
    padding: 1rem;
    width: 100%;
}

.votes{
    /* font-family: Nunito; */
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #5C6A78;
    margin-left: 3rem;
}

@media screen and (max-width: 480px) {
    .flex_div {
        margin-top: 2em;
        margin-left: 1em;
        width: 90%;
    }
    
    .flex_div h4 {
        font-size: 15px;
    }

    .circle{
        font-size: 16px;
        height: 25px;
        width: 25px !important ;
        transform: translateY(-5px); 
    }
    
    .BackBtn{
        margin-left: 7px;
        transform: translateY(0px);
    }

    .container{
        width: 96%;
        margin: 2%;
    }
}