.MainContainer{
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 15px;
    background-color: #FAFEF5;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

.ContentContainer{
    display: grid;
    width: 95%;
    min-height: 100vh;
    grid-column: 2/span 1;
    margin-bottom: 20px;
    padding-top: 10rem;
}

.header{
    transform: translateX(-5px);
    margin-right: 5px;
    z-index: 1;
    grid-column: 1;
    width: 105%;
    margin-bottom: 25px;
    height: max-content;
}

@media only screen and (max-width:450px){

    .MainContainer{
        grid-template-columns: 1fr;
    }

    .ContentContainer{
        width: 90%;
        margin: 0 auto;
    }


}
