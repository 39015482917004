.data-not-found {
    text-align: center;
}

.data-not-found > img {
    max-width: 120px;
    max-height: 150px;
}

.data-not-found .msg1 {
    color: green;
}

.data-not-found > p {
    margin-bottom: 2px;
}