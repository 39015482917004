.skcircle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.skcircle .skchild {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.skcircle .skchild:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #92B860;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.skcircle .skcircle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.skcircle .skcircle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.skcircle .skcircle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.skcircle .skcircle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.skcircle .skcircle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.skcircle .skcircle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.skcircle .skcircle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.skcircle .skcircle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.skcircle .skcircle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.skcircle .skcircle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.skcircle .skcircle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.skcircle .skcircle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.skcircle .skcircle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.skcircle .skcircle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.skcircle .skcircle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.skcircle .skcircle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.skcircle .skcircle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.skcircle .skcircle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.skcircle .skcircle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.skcircle .skcircle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.skcircle .skcircle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.skcircle .skcircle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}