.Backdrop{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.096);
    z-index: 1;
    animation-name: opaque;
    animation-duration: 0.5s;
}

@keyframes opaque{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}