.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 15px;
  }

  @media only screen and (max-width: 800px) {
    .swiper {
        width: 100vw;
        height: 100%;
      }
  }