/* @import "../node_modules/rsuite/dist/rsuite.min.css"; */

body,
html {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rs-picker-daterange-menu {
  z-index: 9999 !important;
  position: fixed !important;
  top: 250px !important;
}

.rs-picker-default .rs-picker-toggle {
  border-width: 1px;
  border-color: #c4c4c4 !important;
  border-radius: 4px;
  padding: 9px !important;
}

.rs-picker-default .rs-picker-toggle-active {
  border-width: 2px !important;
  border-color: #1876d2 !important;
  padding: 8px !important;
  outline: none !important;
  box-shadow: none !important;
}


.rs-picker-toggle-placeholder, .rs-picker-toggle-value, .rs-picker-toggle-textbox {
  color: #0A090B !important;
  font-size: 1rem;
  font-weight: 400;
  border: 0 !important;
}

.rs-picker-toggle-textbox {
  padding-left: 8px !important;
  /* padding-top: 8.5px !important;  */
}

.rs-picker-toggle-caret {
  color: #0A090B !important;
}

.MuiMenu-paper{
  max-width: 600px !important;
  max-height: 300px !important;
}

@media (max-width: 600px) {
  .MuiMenu-paper{
    max-width: 300px !important;
    max-height: 200px !important;
  }
}
.error{
  border:1px solid red;
}

