.jodit-container:not(.jodit_inline) .jodit-workplace {
  background-color: #fff;
  border: 0 solid #dadada;
  max-height: 400px;
}

element.style {
  min-height: 200px;
  min-width: 200px;
  max-width: 100%;
  height: auto;
  width: auto;
  max-height: 200px; /* Add your desired max height */
}

.jodit-ui-group_group_other {
  display: none !important;
}

.jodit-toolbar-button_speechRecognize {
  display: none !important;
}

.jodit-ui-group_group_info {
  display: none !important;
}


/* within the popup  */
.jodit-ui-group .jodit-toolbar-button_fullsize {
  display: none;
}

/* Hide the "about" button */
.jodit-ui-group .jodit-toolbar-button_about {
  display: none;
}

/* Hide the "print" button */
.jodit-ui-group .jodit-toolbar-button_print {
  display: none;
}

/* added now  */

.jodit-context table, .jodit-wysiwyg table {
  border: none;
  border-collapse: collapse;
  empty-cells: show;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  max-width: 100%;
}

.jodit-wysiwyg table tr td{
  padding: 0 ;
  border: 0 ;
}

.jodit-wysiwyg table tr th {
  border: 0 !important;
  padding: 0 !important;
}

.jodit-popup__content {
  position: sticky !important;
  top: 10px !important; /* Adjust as needed */
}
