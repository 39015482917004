
.NormalSlider{
    width: 70%;
}

.CheckboxContainer{
    display: flex;
    flex-wrap: wrap;
}


.questionIndex {
    top: 0;
    left: 0;
    position: absolute;
    width: 51px;
    height: 51px;
    color: #fff;
    background: #4472C4;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    border-radius: 12px 0px;
    transform: translate(-4.3vw, -1.7vw);
    display: flex;
    align-items: center;
    justify-content: center;
}



.Checkboxes{
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.Checkboxes input[type=checkbox]{
    width: 15px;
   height: 15px;
}


.Checkboxes label{
    margin: 0;
    margin-left: 10px;
    font-size: 15px;
    color: #1A1A1A;
}

.OtherInputContainer{
    display: flex;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-top: 10px;
    animation-name: fadeIn;
    animation-duration: 1s;
}

.OtherInputContainer > input[type=text]{
    display: inline-block;
}

.OtherInputContainer > button{
    display: inline-block;
    background-color: #92B860;
    color: #fff;
    border: none;
    margin: 3px;
}


.TripTypeContainer{
    width: 100%;
    animation-name: fadeIn;
    animation-duration: 1s;
    display: flex;
    justify-content: space-between;
}

.TripType{
    text-align: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px 10px;
}

.TripType > p{
    margin: 0;
}

.ActiveTripType{
    color: #99B860;
    border: 1px solid #99B860;
}


.TripIcon{
   font-size: 40px !important;
}

.RadioContainer{
    display: flex;
    flex-wrap: wrap;
}

.RadioBtn{
    width: 50%;
    display: flex;
}

.RadioBtn > input[type=radio]{
    width: 12px;
    height: 12px;
    margin-right: 15px;
    position: relative;
    top: 5px;
}

.Button{
    height: 40px;
    background: #343434 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font: 18px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFBFB;
    padding: 5px 15px;
}

.scale_cont{
    margin: 20px 0;
    margin-left: 15px;
}

.scale_cont > button{
    border: 1px solid #707070;
    color: #707070;
    background: #fff;
}

.scale_cont > button:focus{
    border: 1px solid #92B860;
    color: #92B860;
}

.TextArea{
    width: 416px;
    height: 131px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #707070;
    border-radius: 16px;
    padding: 10px;
}

@keyframes fadeIn{
    from{opacity: 0;}
}

@media only screen and (max-width:450px){

    .input_type_cont{
       display: flex;
       justify-content: center;
    }

    .NormalSlider{
        width: 100%;
    }

}