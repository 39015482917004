.main_container{
    padding: 10px 15px;
}


.slider_cont{
    width: 350px;
    margin: 15px 0 70px 25px;
}


.save_btn_cont{
    margin-top: 30px;
}

.save_btn_cont > input[type=checkbox]{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.save_btn_cont > label{
    color: #92B860;
}

.dependent_btn_cont{
    cursor: pointer;
}

.dependent_btn_cont > label{
    margin-left: 5px;
    text-decoration: underline;
    color: #5B5B5B;
    cursor: pointer;
    margin-top: 15px;
}

.OptionToDependCont{
    border-left: 4px solid #99B860;
    padding: 0 15px ;
    margin-top: 10%;
}

.OptionSelectCont{
  display: flex;
  align-items: center;
}

.OptionSelect{
    padding: 5px;
    margin-left: 10px;
}

.DependentQuestion{
    margin-top: 10px;
}

.DependentQuestion > input{
    width: 200px;
    border-right: 3px solid #ccc;
}

.checkboxes_cont{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    color: #4D4D4D;
}

.checkbox_cont,
.checkbox_input_cont{
    display: flex;
    align-items: center;
    width: 50%;
    margin-top: 10px;
}

.checkbox_cont > label{
    margin: 0;
    margin-left: 10px;
}

.checkbox_cont > input,
.checkbox_input_cont > input{
    width: 15px;
    height: 15px;
}

.checkbox_add_option{
    color: #92B860;
    text-decoration: underline;
    cursor: pointer;
}

.checkbox_cont > input[type=text],
.checkbox_input_cont > input[type=text]{
    width: 180px;
    margin-left: 10px;
    border-right: 3px solid #ddd;
    height: 30px;
}

.checkbox_head > span{
    margin-left: 10px;
    color: #4D4D4D;
}

.textbox_cont{
    margin-bottom: 20px;
}

.textbox_cont > input[type=text]{
    border: 1px solid #707070;
    width: 150%;
    height: 166px;
}

.options_cont{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-left: 30px;
    align-items: center;
}

.option_cont{
  margin-right: 5px;
}

.option_cont > span{
    padding: 5px;
    background-color: #343434;
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.option_add_option{
    border: 1px solid #343434;
    color: #343434;
    padding: 5px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}

.option_add_option > span{
    margin-left: 10px;
}

.option_input_cont > input[type=text]{
    width: 180px;
    border-right: 3px solid #ddd;
}

.scale_cont{
    margin: 20px 0;
    margin-left: 15px;
}

.scale_cont > button{
    border: 1px solid #707070;
    color: #707070;
    background: #fff;
}

.scale_cont > button:focus{
    border: 1px solid #92B860;
    color: #92B860;
}

@media only screen and (max-width:450px){

    .slider_cont{
        width: 250px;
    }

    .save_btn_cont{
        margin-bottom: 10px;
    }

    .save_btn_cont > label{
        display: inline;
    }

    .checkbox_input_cont > input[type=text]{
        width: 70%;
    }

    .checkbox_cont{
        margin-bottom: 10px;
    }

    .options_cont{
        padding-left: 0;
    }

    .option_cont{
        margin-right: 5px;
    }

}