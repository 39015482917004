.dropdown-list:focus{
    outline: none;
    cursor: pointer;
  }


  .HotelImage{
    display: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -45px;
  }
  
.dropdown-list{
  width:70%;
  height: 150%;
  border: none;
}

.hotellist{
  margin-bottom: 10%;
}

@media (max-width: 480px) {
  .dropdown-list{
    padding-left: 20px;
    max-width: 480px;
    height: auto;
    border: black;
  }
}
