.MainContainer{
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 15px;
    background-color: #FAFEF5;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

.ContentContainer{
    display: grid;
    width: 95%;
    min-height: 100vh;
    grid-column: 2/span 1;
    margin-bottom: 20px;
    /* padding-top: 10rem; */
}

.CreateSurveyHeader{
    background-color: #fff;
    margin-top: 15px;
    padding: 20px;

}

.CurrentlyActiveCont{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    height: 130px;
    justify-items: center;
    align-items: flex-end;
}

.IconContainer{
    text-align: center;
}

.IconContainer > p{
    margin: 0;
}

.IconContainer > p:last-child{
    text-decoration: underline;
    color: #474747;
    margin-top: 10px;
    cursor: pointer;
}

.SurveySmileIcon{
    font-size: 22px;
}

.SurveyIcon{
    margin: 0 2px;
    color: #92B860;
}

.QuestionNumber{
    text-align: center;
}

.QuestionNumber > p{
    margin: 0;
    color: #474747;
}

.QuestionNumber > p:first-child{
    font-size: 55px !important;
    color: #92B860;
}

.SurveyTemplatesTableCont{
    background-color: #fff;
    margin-top: 15px;
    padding: 20px;
    margin-bottom: 10%;
}

.AddNewCont{
    display: flex;
    margin-right: 20px;
    margin-bottom: 10px;
}

.AddNewCont > select{
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #BEBEBE;
    position: relative;
    top: -6px;
}


.CreateNewBtn:hover{
    transform: scale(1.05);
}

.CreateNewBtn > img{
    width: 20px;
    margin-right: 10px;
}

.survey_list_cont{
    height: 350px;
    overflow-y: scroll;
}

.create_new_survey_popup{
    z-index: 999;
    position: fixed;
    top: 12%;
    left: 32%;
    background: #fff;
    box-shadow: 0 2px 2px #ccc;
    min-width: 35%;
    max-height: 520px;
    border-radius: 12px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.createSurveyImg{
    max-height: 500px;
    margin-left: 6%;
    justify-content: center;
}

.create_new_survey_popup_fields{
    font-family: 'nunito';
    margin-top: 7%;
    font-size: 1.1em;
}

.create_new_survey_popup_fields > label{
    color: #707070;
    font-size: 0.8em;
}

.create_new_survey_popup_fields > input{
    border: 1px solid #ccc;
    height: 40px;
}

.create_new_survey_popup_fields > select{
    padding: 10px;
    
    /* border: 1px solid #7EA63F;
    color: #7EA63F; */
}

.create_new_survey_popup_btns{
    display: flex;
    justify-content: space-between;
}

.create_new_survey_popup_btns > button{
    background: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #7EA63F;
    border: 1px solid #7EA63F;
}

.create_new_survey_popup_btns > button:last-child{
    border: 1px solid salmon;
    color: salmon;
}

.surveyInput{
    background-color: transparent;
    box-shadow: none;
}

.inputLine{
    margin-top: 3%;
    height:1px;
    background-color: #d8d8d8;
}

.SurveyTemplatesTable{
    width: 95%;
    margin: 0 auto;
    padding-top: 20px;
}

.SurveyTemplatesTable th, 
.SurveyTemplatesTable td{
    text-align: center;
    padding: 10px;
    color: #616161;
}

.SurveyTemplatesTable > tr > th{
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
}

.DeleteIcon,
.EditIcon{
    font-size: 30px;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.DeleteIcon{
    background-color: #FF6161;
    margin-right: 10px;
}

.EditIcon{
    background-color: #7EA63F;
}

.OuterContainer{
    transform: translateY(-5%);
}

.CreateNewSurveyCont{
    margin-left: 2%;
    width: 97%;
    margin-top: 10px;
    border-radius: 12px;
    background-color: #fff;
}

.ChangeCoverButton{
    z-index:1000;
    position: absolute;
    /* right:6%;
    transform: translateY(17px); */
    top: 20px;
    right: 15px;
    min-width: 40px;
}

.SurveyCover{
    width:100%;
    object-fit: cover;
    max-height: 250px;
    border-radius: 12px;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
}

.SurveyInfoRow1{
    margin: 30px;
    font-size: 24px;
    font-family: 'nunito';
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

/* .SurveyInfoRow1 input{
    width: max-content;
    max-height: 30px;
    padding:0;
} */

.NameEditButton{
    background-color: black;
    cursor: none;
}

.SurveyInfoRow2 {
    margin: 24px;
    /* padding: 0 16px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 800px;  or whatever maximum width you prefer */
    box-sizing: border-box;
}

.CreateNewSurveyCont input:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.EditTemplatesLanguageHead{
    display: flex;
    align-items: center;
    border: 1px solid #7EA63F;
    width: 100%;
    color: #7EA63F;
    cursor: pointer;
}

.EditTemplatesLanguageHead > p{
    margin: 0;
    font-size: 18px;
    padding: 5px 0;
    padding-left: 5px;
}

.EditTemplatesLanguageHead > .angleIcon{
    display: block;
    text-align: center;
    font-size: 20px;
    margin-right: 50%;
    margin-left: auto;
}

.EditTemplatesCont{
    margin:0px;
}

.BackBtn{
    position: absolute;
    font-size: 20px;
    top: 10px;
    left: -22px;
    cursor: pointer;
}

.EditTemplatesHead > input[type=text]{
    /* border: 0.2px solid #707070; */
    border: none;
    width: 180px;
    font-size: 20px;
}

.EditTemplatesHead > button:last-child{
    margin-left: auto;
    margin-top: 0;
}

.EditTemplatesSection{
    margin-bottom: 25px;
    border-radius: 12px 12px 0 0;
}

.EditTemplatesSectionHead{
    display: flex;
    background-color: #7ea63f;
    color: #fff;
    font-size: 1.2em;
    margin: 0;
    font-weight: 600;
    border-radius: 12px 12px 0 0;
    padding: 12px;
}

.SectionHeadNameInput {
    width: 20%;
    background-color: transparent;
    
    border-bottom: solid white 1px ;
    transform: translateY(-5px);
}

.AddSectionInput{
    border:none;
    box-shadow: none;
    border-bottom: 1px solid gray;
    border-radius: 0;
}

.SectionHeadButtons{
    margin-left: auto;
    margin-right: 3%;
}

.EditTemplateQuestion{
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    color: #5B5B5B;
    padding: 10px 15px;
}

.addNewSecCont {
    margin-left: 38%;
    margin-top:5%;
}

.ActionIconCont{
    display: flex;
    justify-content: flex-end;
}

.AddIcon{
    margin-right: 5px;
    cursor: pointer;
}

.OptionIcon,
.QuestionIcon,
.TemplateEditIcon,
.QuestionCloseIcon,
.TemplateDeleteIcon{
    margin-left: 15px;
    font-size: 18px;
    cursor: pointer;
}

.QuestionCloseIcon{
    color: salmon;
}

.QuestionIcon{
    color: #92B860;
}

.TemplateEditIcon{
    color: #000;
}

.TemplateDeleteIcon{
    color: #FF6161;
}

.NewQuestionInput{
    width: 50%;
    background-color: transparent;
    border:none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid gray;
    font-weight: 600;
    font-family: 'nunito';
}

.QuestionTypes{
    padding: 10px 15px;
}

.QuestionTypesHead{
    font-size: 1em;
    font-weight: 700;
}

.QuestionTypesCont{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.QuestionTypesCont > div{
    display: grid;
    grid-template-columns: 20px auto;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.QuestionTypesCont > div:hover{
    background-color: #7fa63f6e;
    cursor: pointer;
}

.QuestionTypeCont{
    width: 80%;
    margin-top: 10px;
}

.QuestionTypeCont > input[type=checkbox],
.QuestionTypeCont > input[type=radio]{
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.QuestionTypeCont > input[type=text]{
    border: 1px solid #707070;
}

.QuestionTypeScaleCont > span{
    border: 0.2px solid #707070;
    padding: 4px;
    font-size: 10px;
}

.QuestionTypeYesNoCont{
    margin-top: 10px;
}

.QuestionTypeYesNoCont > span{
    color: #fff;
    background-color: #1A1A1A;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 5px;
}

.TemplateQuesCont{
    padding: 10px 15px;
}

.done_btn{
    display: block;
    margin-left: auto;
    /* width: 80px; */
    border: 1px solid #92B860;
    background-color: #92B860;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.arrow_right{
    margin-left: 5px;
}

.QuestionsListCont{
    display: grid;
    grid-template-columns: 25px auto;
    align-items: flex-start;
    grid-column-gap: 5px;
}

.info_logo{
    width: 20px;
    position: relative;
    top: 6px;
}

.ques_list_head{
    font-size: 22px;
    margin-bottom: 30px;
}

.section_head{
    color: #7EA63F;
    font-size: 20px;
    margin: 0;
    margin: 10px 0;
}

.question_cont{
    display: flex;
    align-items: center;
    border: 0.5px solid #AFAFAF;
    border-radius: 5px;
    padding: 10px;
    background: #FAFEF5 0% 0% no-repeat padding-box;
    width: 100%;
}

.question_cont > p{
    margin: 0;
}

.new_ques > p{
    font-weight: bold;
}

.comment_icon{
    margin-left: auto;
}

.createNewSectionBtn{
    width: 200px;
    padding: 8px;
    background: none;
    border: 1px solid #7EA63F;
    border-radius: 5px;
    color: #7EA63F;
}

.SharePopupGuestForm label{
    font: 600 1.1em 'nunito';
}

.SharePopupGuestForm input{
    display: block;
    min-width: 50%;
    max-width: max-content;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #d8d8d8;
}

.shareByEmail{
    margin-top: 2%;
    font: 600 1.1em 'nunito';
}

.emailInput{
    margin-top: 3%;
    display: flex;
}

.shareButton{
    margin-left: 5%;
}

.surveyLinkHeading{
    margin-top: 3%;
    font: 600 1.1em 'nunito';
    color: #7EA63F;
    width: max-content;
}

.surveyLinkHeading:hover{
    opacity: 0.75;
    cursor: pointer;
}

.surveyLinkContainer{
    display: flex;
    margin-top: 5%;
}

.createSurveyContent{
    margin-left:10%;
}

@media only screen and (max-width:450px){

    .CurrentlyActiveCont{
        align-items: flex-start;
        grid-column-gap: 10px;
        margin-top: 10px;
    }

    .QuestionNumber > p:first-child{
        font-size: 45px !important;
    }

    .heading{
        font-size: 15px !important;
    }

    .CreateSurveyHeader{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        
    }

    .SurveyTemplatesTableCont{
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .CreateNewBtn{
        width: 130px;
        font-size: 10px !important;
        margin-right: 5px;
    }

    .CreateNewBtn > img{
        width: 12px;
    }

    .CreateNewSurveyCont{
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        padding: 20px 20px;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    
    .BackBtn{
        font-size: 15px !important;
        left: -17px;
    }

    .QuestionTypesCont{
        grid-template-columns: 1fr;
    }

    .QuestionTypeScaleCont{
        margin-top: 10px;
    }

}