.positive {
    position: relative;
    transition: all 1s;
    background-color: #D9F2DD;
}

.negative {
    position: relative;
    transition: all 1s;
    background-color: #F2DBD9;
}

.mixed {
    position: relative;
    transition: all 1s;
    background-color: #9bbdf06b;
}

.neutral {
    position: relative;
    transition: all 1s;
    background-color: #e8bd6d3d;
}