.Date_range{
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    margin-top: 5px;
    cursor: pointer;
}

.SideNavDateWidgetContainer{
    width: max-content;
    justify-self: center;
}

.SideNavDateWidgetContainer > span{
    display: block;
    color: #fff;
}

.Date_range p{
    margin: 0;
}

.Date_range .comparedTo{
    /* color: #5D5D5D; */
    color: #fff;
    text-transform: lowercase;
}

.Date_range .comparedTo::before,
.Date_range .comparedTo::after{
  content: '';
  display: inline-block;
  height: 1px;
  width: 30px;
  background-color: #ccc;
  margin: 3px;
}

.Date_range:focus{
    outline:none
  }

.date_widget_container{
    display:block;
    position: fixed;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #484848;
    border-radius: 3px;
    opacity: 1;
    margin-left:auto;
    left:34%;
    top:25%;
    z-index: 21;
    padding:40px;
    padding-top:15px;
    padding-bottom:15px;
    width:510px;
    animation-name: bottomUp;
    animation-duration: 0.3s;
}

@keyframes bottomUp{
    from{
        transform: translateY(100%);
    }
    to{
        transform: translateY(0);
    }
}

.date_range_head{
    font: normal normal  18px Helvetica;
letter-spacing: 0px;
color: #99B860;
opacity: 1;
text-align: center;
}

.period{
    margin:0 5px;
}

.period_header{
    text-align: left;
font: normal normal normal 18px/18px Helvetica;
letter-spacing: 0px;
color: #656565;
opacity: 1;
}

.periodSelector{
    padding: 5px;
    width: 100%;
}

.selected_period,.selected_period2{
    text-align: left;
    justify-items: space-evenly;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 3px;
    opacity: 1;
    width:200px;
    font: normal normal  16px/28px Helvetica Neue;
    letter-spacing: 0px;
    color: #D9D9D9;
    opacity: 1;
}

.selected_period2{
    color: #656565
}
.selected_period:focus{
    outline: none;
}
.select_angle{
    margin-left: auto;
    float: right;
    transform: translateY(5px);
}

.date_range_widget{
    display: flex;
    justify-content: space-between;
}

.interval{
    display: flex;
    justify-content: space-between;
}

.showInterval{
    visibility: visible;
    animation-name: faded;
    animation-duration: 0.8s;
}

@keyframes faded{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.interval_span{
    font: normal normal  14px/24px Helvetica Neue;
    letter-spacing: 0px;
    color: #656565;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 3px;
    opacity: 1;
    padding:5px;
    padding-left:8px;
    padding-right:8px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.interval_span>p{
    margin-bottom:0;
}

.interval_span_date_input{
    height: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
}

.react-datepicker__input-container input{
    padding: 0;
    height: auto;
}

.horizontal_line{
    color: #656565;
    transform: translateY(10px);
}

.vertical_line{
    color: #656565;
    transform: translateY(35px);
   
}

.input_date_error{
    color: red;
    margin-left: 5px;
    animation-name: faded;
    animation-duration: 0.5s;
}

@keyframes slideIn{
    from{
        transform: translateX(-50%);
    }
    to{
        transform: translateX(0);
    }
}

.comparision_btn{
margin-top:12px;
background: #99B860 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
text-align: center;
padding-top:7px;
padding-bottom: 7px;
width:100%;
border:none;
font: normal normal normal 17px Helvetica;
letter-spacing: 0px;
color: #FAFEF5;
}

.selected_dropdown{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    position:absolute;
    padding:10px;
    padding-top:2px;
    padding-bottom:5px;
    z-index: 2;   
    width:150px;
    font: normal normal  15px Helvetica ;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    transform: translateX(50px);
    display: none;
}

.selected_dropdown_list{
    list-style-type: none;
    padding-left: 7px;
    padding-top: 5px;
    margin-top:5px;
    margin-bottom:5px
}

.selected_dropdown_list>li{
    margin:3px
}
li>b{
    font-size:17px  ;      
}
.show_drop{
    display: block;
}
.comparision_btn:focus{
    outline: none;
}


/* MEDIA QUERIES   */

@media (min-width: 768px) and (max-width: 1024px){

    .date_widget_container{
        position: fixed;
        width: 70%;
        left: 15%;
    }

}

@media only screen and (max-width:450px){

    .date_widget_container{
        position: fixed;
        width: 320px;
        left: 6%;
        padding: 18px;
        top: 5%;
    }

}