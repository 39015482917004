html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

#root, .App {
  width: 100%;
  height: 100%;
}

.primary_btn {
  border: 1px solid #7ea63f;
  border-radius: 3px;
  padding: 6px 18px;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  color: #7ea63f;
  opacity: 1;
  
  text-decoration: none !important;
}

.default_btn {
  border: 1px solid #7ea63f;
  border-radius: 3px;
  background-color: transparent;
  padding: 6px 18px;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  color: #7ea63f;
  opacity: 1;
  
  text-decoration: none !important;
}

.default_btn:hover {
  border: 2px solid #7ea63f;
  color: #7ea63f !important;
}

.green {
  color: #7ea63f;
}

.red {
  color: red;
}

.grecaptcha-badge {
  visibility: hidden;
}
.g-recaptcha .rc-anchor-error-msg {
  display: none;
}

#fr-logo{
  display: none;
}

/* iframe#webpack-dev-server-client-overlay{display:none!important} */