.MuiDrawer-paper::-webkit-scrollbar {
  width: 6px;
  z-index: 11;
  background-color:#283953bd;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb{
  background: #eaecee; 
  border-radius: 5px;
}

.SideNav_container {
  display: grid;
  align-content: flex-start;
  background-color: #ffffff;
  /* padding-top: 45px; */
  width: 250px;
  height: auto;
  z-index: 2;
}

.nav_side{
  padding-top: 30px;
  background-size: cover;
}

.SidenavHeadingContainer{
  background-size: cover;
  background-position: center;
  position: relative;
  
}

.SidenavHeadingContainer > img{
  width: 100%;
 height: 150px;
 object-fit: cover;
}

.SidenavImageBackdrop{
  width: 100%;
  height: 100%;
  background-color: #68686852;
  position: absolute;
  top: 0;
  left: 0;
}

.SidenavHeadingContent{
  position: relative;
}

.logo_sidenav_container {
  width: 100%;
  height: 8.5rem;
}

.SideNav_logo {
  max-height: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
  object-fit: scale-down;
  width: 100%;
}
.SideNav_by {
  position: relative;
  left: 75px;
  font-family: 'Lato';
  font: Regular 24px/28px 'Lato';
  letter-spacing: 0px;
  /* color: #a1a1a1; */
  color: #fff;
  opacity: 1;
}
.SideNav_header {
  font-family: 'Arial';
  text-align: center;
  font-size: 23px;
  color: #fff;
  padding: 25px;
}
.nav-item {
  width: 100%;

  font-family: 'Lato';
  font-size: 14px;
}
.nav-item > p {
  margin-bottom: 15px;
  padding: 0px;
  padding-left: 25px;
  width: 100%;
  cursor: pointer;
}

.active {
  /* border: 8px solid #7ea63f; */
  border-left: none;
  border-top: none;
  border-bottom: none;
  /* color: #7ea63f; */
  height: 25px;
  margin-bottom: 15px;
}

.Date {
  font-family: Nunito;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
}

.Range{
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #807E7E;
}
.filters {
  display: flex;
  height: max-content;
  width: 60%;
  margin-right: 4%;
  background: #FFFFFF;
  border: 1.4px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 12px;
}

.expand-btn{
  display: flex;
  align-items: center;
}

.expand-btn span{
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #444E59;
  margin-left: 0.5rem;
}

.filters_head {
  font-family: 'Lato';
  font-weight: bold;
  font: Bold 18px/32px 'Lato';
  letter-spacing: 0px;
  /* color: #656565; */
  color: #fff;
  opacity: 1;
}

.filters > select {
  text-align: left;
  font: lighter 15px/28px 'Lato';
  letter-spacing: 0px;
  color: #bebebe;
  opacity: 1;
  margin-right: 5px;
  margin-top: 5px;
  padding: 2px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  cursor: pointer;
}


.nav > a {
  width: 250px;
  /* color: #bebebe; */
  color: #fff;
}

.nav > a:hover {
  color: #bebebe;
}

.SidenavFiltersContainer{
  margin: 3px 7px;
  display: grid;
  min-width: 110px;
  justify-items: center;
}

.SidenavFiltersContainer > span{
  display: block;
  text-align: center;
  /* letter-spacing: 0.13px; */
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
  opacity: 1;
}

.filter-saperater{
  border: 1px solid #BEBEBE;
  height: 2rem;
  margin: 14px;
}

.SidenavFiltersContainer > select{
  /* width: 81%;
  padding: 5px;
  margin-top: 2px;
  background: #ffffff00 0% 0% no-repeat padding-box;
  border: 1px solid #A5A5A5;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
  font: normal normal 300 15px/28px Helvetica;
  color: white; */
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #807E7E;
  border: none;
  appearance: none;
  width: max-content;
  cursor: pointer;
  /* text-indent: 42%; this is to center align only the selected option */
  text-align: center;
  
}

.SidenavFiltersContainer > select > option {
  background: #fff;
  color: #283953;
}

.filter-container{
  display: grid;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}


@media (min-width: 768px) and (max-width: 1024px){

  .SideNav_container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 95vh;
    animation: sidenav 1s;
    overflow-y: auto;
    padding-bottom: 20px;
    box-shadow: 1px  2px 10px #00000029;
  }

  .filter-container{
    grid-template-columns: 1fr 1fr 1fr;
  }

  .SideNav_container::-webkit-scrollbar{
    display: none;
  }

}

@media (min-width: 768px) and (max-width: 1024px){

  .SideNav_container{
    padding-top: 15%;
  }

}

@media only screen and (max-width: 480px){
  * {
    box-sizing: border-box;
  }

  .SideNav_container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 95vh;
    animation: sidenav 1s;
    overflow-y: auto;
    padding-bottom: 20px;
    box-shadow: 1px  2px 10px #00000029;
    width: 100%;
  }

  .SideNav_container::-webkit-scrollbar{
    display: none;
  }

  .filters {
    position: absolute;
    padding-top: 50px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    z-index: 1;
  }

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    padding-left: 2%;
  }

  .filter {
    max-width: 75%;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .expand-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 25%;
    margin-top: 0.5rem;
    
  }
  
  .expand-btn span{
    font-family: Nunito;
    align-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #444E59;
    margin-left: 0.5rem;
  }

  .SidenavFiltersContainer {
    margin: 0;
    display: grid;
    width: 15vw;
    min-width: 110px;
    justify-items: center;
  }
  
  .SidenavFiltersContainer > span {
    display: block;
    text-align: center;
    /* letter-spacing: 0.13px; */
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
    opacity: 1;
  }
  
}





@keyframes sidenav{
  from {transform: translateX(-50%);}
  to {transform: translateX(0);}
}
