.carousel_cont{
    display: grid;
    grid-template-columns: 15px auto 15px;
    align-items: center;
    justify-items: center;
    grid-column-gap: 5px;
    overflow: hidden;
}

.carousel_left_control_cont,
.carousel_right_control_cont{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.carousel_left_control_cont:hover,
.carousel_right_control_cont:hover{
    background-color: rgba(0, 0, 0, 0.349);
}


.carousel_left_control_cont:hover .carousel_left_control{
    color: #fff;
}

.carousel_right_control_cont:hover .carousel_right_control{
    color: #fff;
}

.carousel_left_control,
.carousel_right_control{
   font-size: 25px !important;
}

.dot_container{
    margin-top: 5px;
    display: flex;
    justify-content: space-evenly;
}

.dot_container > span{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.children_cont > div{
    animation-name: slideLeft;
    animation-duration: 0.5s;
}

@keyframes slideLeft{
    from{
        transform: translateX(80%);
        -webkit-transform: translateX(80%);
        -moz-transform: translateX(80%);
        -ms-transform: translateX(80%);
        -o-transform: translateX(80%);
}
}