.Container{
    display: none;
    margin: 10px 0;
    margin-left: auto;
    margin-right: 15px;
    text-align: right;
}

.Left, .Right{
    font-size: 25px !important;
    margin: 0 5px;
    color: #7ea63f;
}


.Opaque{
    opacity: 50%;
}

@media only screen and (max-width:450px){
    .Container{
        display: block;
    }
}